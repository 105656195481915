import { Checkbox, Dropdown } from "antd";
import type { TableColumnsType, CheckboxProps, MenuProps } from 'antd';
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

type MenuItem = Required<MenuProps>['items'][number];

interface CheckedProps {
    item: string;
    checked: boolean;
}

function getItem(
    label: React.ReactNode,
    key: React.Key,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        children,
        label,
    } as MenuItem;
}

interface Position {
    x: number;
    y: number;
}

export function ColumnDropdown({ columns, menuVisible, position, isChecked, setChecked }: { columns: TableColumnsType<any>, menuVisible: boolean, position: Position, isChecked: CheckedProps[], setChecked: Dispatch<SetStateAction<CheckedProps[]>> }) {
    position = position || { x: 0, y: 0 };

    const updateChecked = (item: string | undefined, checked: boolean) => {
        setChecked(prevState => prevState.map((entry) => (entry.item === item ? { ...entry, checked } : entry)));
    };

    const onChange: CheckboxProps['onChange'] = (e: CheckboxChangeEvent) => {
        const itemName = e.target.name;
        const itemChecked = e.target.checked;
        updateChecked(itemName, itemChecked);
        console.log(e)
        // Immediately update isChecked state to reflect the new changes
        isChecked.map((entry) =>
            entry.item === itemName ? { ...entry, checked: itemChecked } : entry
        );
    };
    const columnMenu: MenuProps['items'] = columns.map((item) =>
        getItem(<Checkbox
            onChange={onChange}
            name={item.title}
            checked={isChecked.find(x => x.item === item.title)?.checked}>
            {item.title}
        </Checkbox>, item.title));
    useEffect(() => {
        console.log(isChecked);
    }, [isChecked]);

    return (<Dropdown menu={{ items: columnMenu }} open={menuVisible} overlayStyle={{ left: `${position.x}px`, top: `${position.y}px` }} ><></>
    </Dropdown>);

}