import { useMemo } from "react";
import { MenuItem } from "./menuItem";
import { SubMenu } from './SubMenu';
import { 
    UserPagePermisions, 
    uppData, 
    AdminList, 
    PermissionsObject, 
    Permissions, 
    UserData  
} from "./types/globalTypes";
import { Link, NavLink } from "react-router-dom";


//uppMap is for UserType 5 and 7, this is all it needs
const uppMap: uppData = {
    Administrator: {
        url: "/Credentials/Subcontractor/SubcontractorAccess",
        icon: "admin_panel_settings",
        text: "Admin",
    },
    Dispatch: {
        url: "/Credentials/Subcontractor/SubcontractorDispatch",
        icon: "airport_shuttle",
        text: "Dispatch"
    }
}

// --------------------------------------------------------------------------------
// This area is where we will put our todos and bugs needed to be fixed for this project
// for when we leave for the day.
// --------------------------------------------------------------------------------
// TODO:
// 1. Make the sidemenu not appear if user has not clicked the hamburger menu
// 2. Make the sidemenu appear (near clicked) if user has clicked the hamburger menu
// 3. Make the sidemenu disappear if user has clicked the hamburger menu again
// 4. Make the sidemenu disappear if user has clicked outside of the sidemenu
// 5. Fix the URL for all buttons. (Not redirecting you to the correct page)
// 6. Get the styling matching with old website 
// --------------------------------------------------------------------------------
// COMPLETED:
// 1. Make the sidemenu appear all buttons needed with the permission set
// 2. Make nested submenus appear (indefinitely)
// 3. Moved the types to a separate file
// Added by: Alex R.
// Date: 11/9/2023



const Menu = ({ permissions, parentCollapsed, userType }) => {

    const userData: UserData = useMemo(() => {
        const temp = {
            userType: userType,
            userPermissions: permissions,
            adminList: JSON.parse(window.localStorage.getItem("adminList") ?? "{}"),
            userPagePermissions: JSON.parse(window.localStorage.getItem("userPagePermissions") ?? "{}")
        }
        return temp 
    }, [permissions]);
    const baseUrl = window.localStorage.getItem("Referrer");
    const sidebarMenu: uppData = useMemo(() => {
        const permissions = userData.userPermissions
        const upMap: uppData = {};

        for (const key in permissions) {
            const element: PermissionsObject = permissions[key]

            if (!upMap[element.area]) {
                upMap[element.area] =  {
                    icon: element.icon,
                    url: `#${element.area}`,
                    text: element.area,
                    permissionId: element.permissionId,
                    //subMenu: [{ url: element.url, text: element.displayName }]
                }
                if (!element.subMenu) {
                    upMap[element.area].subMenu = [{ url: baseUrl + element.url, text: element.displayName }];
                } else {
                    upMap[element.area].subMenu = [{ text: element.subMenu, subMenu: [{ url: baseUrl + element.url, text: element.displayName }] }];
                }
            } else {
                if (element.subMenu) {
                    const index: number = upMap[element.area].subMenu?.findIndex(x => x.text == element.subMenu) ?? -1;

                    if (index != -1) {
                        upMap[element.area].subMenu[index]?.subMenu?.push({
                            url: baseUrl + element.url,
                            text: element.displayName,
                            permissionId: element.permissionId,
                        });
                    } else {
                        upMap[element.area].subMenu?.push({
                            text: element.subMenu,
                            subMenu: [{ url: baseUrl + element.url, text: element.displayName }]
                        });
                    }
                } else {
                    upMap[element.area].subMenu?.push({
                        text: element.displayName,
                        url: baseUrl + element.url,
                        permissionId: element.permissionId,
                    });
                }

            }
        }
        return upMap;
    }, [userData])


    //function buildUserPagePermissions(userPagePermissions: UserPagePermisions | null) {
    //    return Object.keys(uppMap).forEach((element) => {
    //        if (userPagePermissions != null) {
    //            if (userPagePermissions[element]) {
    //                return <MenuItem url={uppMap[element].url?y} icon={uppMap[element].icon} text={uppMap[element].text} />
    //            }
    //        }
    //    }
    //    );
    //}                 {/*{(userData.userType == 5 || userData.userType == 7) ?? buildUserPagePermissions(userData.userPagePermissions)}*/}


    return (
        <div className="sidebar">
            <ul id="dynMenu" className="sidebar-menu">
                <li key={"23421" } className="treeview">
                    <Link to={baseUrl + "/Account/Dashboard"}>
                        <div className="new-icon-Container">
                            <span className="material-icons">home</span>
                        </div>
                        <span>Home</span>
                        <span className="material-icons md-14 pull-right" />
                    </Link>
                </li>

                {
                    Object.values(sidebarMenu).map((elem) =>
                        <MenuItem key={JSON.stringify(elem)} url={elem.url} icon={elem.icon ?? ""} text={elem.text ?? ""} subMenu={elem.subMenu} permissionId={elem.permissionId} parentCollapsed={parentCollapsed} />
                    )
                }
                {userType == 3 &&
                    <li key={"23422"} className="treeview">
                    <NavLink to={"/grievances"}>
                        <div className="new-icon-Container">
                            <span className="material-icons">feedback</span>
                        </div>
                        <span>Grievances</span>
                        <span className="material-icons md-14 pull-right" />
                    </NavLink>
                </li>}  
                {userType == 1 &&
                    <li key={"23422"} className="treeview">
                        <NavLink to="/complaints/search">
                            <div className="new-icon-Container">
                                <span className="material-icons">feedback</span>
                            </div>
                            <span>Complaints</span>
                            <span className="material-icons md-14 pull-right" />
                        </NavLink>
                    </li>}
            </ul>
        </div>);
};
export default Menu