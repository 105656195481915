import React, {useEffect} from 'react';
import { redirect} from 'react-router-dom';
import {checkToken} from '../helpers/commonHelpers'

export function Authentication({children}: any){

  

    useEffect(() => {
        if (!checkToken()) {
            window.location.href = 'https://ccerrts.callthecar.com/'
        }
    }, []);

    return children;
}