import axios from 'axios'
let v1 = '/api/v1'

const https = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true,
});

export const https_ = axios.create({
  // timeout: 5000,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  withCredentials: true,
});

export default https






