import { useEffect, useState, useRef } from 'react'
import './App.css'
import Layout from './components/layout/layout';
import { Outlet } from 'react-router-dom';
import https from './service/httpsService';

const App = () => {
    const dataFetch = useRef(false)

    const [count, setCount] = useState(null);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        if (dataFetch.current) return;

        dataFetch.current = true

        https.get("/api/v1/Account/Permissions").then((response) => {
            return response.data
        })
            .then((data) => {
                setData(data);
                window.localStorage.setItem('userData', JSON.stringify(data))
            }).catch((reason) => {
                console.log(reason)
            })
    }, []);

    return (
        <Layout data={data}>
            <Outlet />
        </Layout>
    )
};

export default App
