import { useState } from "react";
import { SubMenu } from "./SubMenu"
import { SubMenuData } from "./types/globalTypes";

export function MenuItem({ url, icon, text, permissionId, subMenu, parentCollapsed }: {
    url?: string,
    icon: string,
    text: string,
    permissionId?: number,
    subMenu?: SubMenuData[],
    parentCollapsed: boolean
}) {
    const [isCollapsed, setCollapse] = useState(false);

    const collapse = () => {
        if (parentCollapsed)
            setCollapse(!isCollapsed);
    }

    const open = () => {
        console.log(open);
        if (parentCollapsed)
            setCollapse(true);
    }

    const close = () => {
        if (parentCollapsed)
            setCollapse(false);
    }

    return (
        <li className="treeview" onMouseEnter={open} onMouseLeave={close}>

            <details open={isCollapsed}>
                <summary>

                    <div className="new-icon-Container">
                        <span className="material-icons">
                            {icon}
                        </span>
                    </div>
                    <span>{text}</span>
                    {/*<span className="material-icons md-14 pull-right">chevron_left</span>*/}

                </summary>
                <SubMenu key={text + icon + permissionId} url={url ?? ""} text={text ?? ""} submenuitems={subMenu ?? [{}]} permissionId={permissionId} openParent={open} closeParent={close} />
                
            </details>
            </li >
    );
}