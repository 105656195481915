import { EventHandler, useState } from "react";
import { SubMenuData } from "./types/globalTypes"

export function SubMenu({ url, text, permissionId, submenuitems, openParent, closeParent }: {
    url: string,
    text: string,
    permissionId?: number,
    submenuitems: SubMenuData[] | undefined,
    openParent?: React.MouseEventHandler,
    closeParent?: React.MouseEventHandler
}) {

    return (
        <ul id={text} key={permissionId} className="treeview-menu" onMouseEnter={openParent} >

            {
                submenuitems?.map((element) => {
                    if (element.subMenu != undefined)
                        return <SubSubMenuItem url={element.url ?? ""} text={element.text ?? ""} submenuitems={element?.subMenu ?? undefined} permissionId={permissionId} />
                    else
                        return <SubMenuItem url={element.url ?? ""} text={element.text ?? ""} submenuitems={element?.subMenu ?? undefined} permissionId={permissionId} />
                })
            }

        </ul>
    )
}

export function SubMenuItem({ url, text, permissionId, submenuitems }: {
    url: string,
    text: string,
    permissionId?: number,
    submenuitems: SubMenuData[] | undefined
}) {
    const [isCollapsed, setCollapse] = useState(false);
    return (<li key={permissionId + text}>

        <a href={url}>{text}</a>

        {
            submenuitems?.map((element) => {
                    return <SubMenuItem url={element.url ?? ""} text={element.text ?? ""} submenuitems={element?.subMenu ?? undefined} permissionId={permissionId} />
            })
        }

    </li>)
}

export function SubSubMenuItem({ url, text, permissionId, submenuitems }: {
    url: string,
    text: string,
    permissionId?: number,
    submenuitems: SubMenuData[] | undefined
}) {
    const [isCollapsed, setCollapse] = useState(false);
    return (<li key={permissionId + text}>

        <details open={isCollapsed}>
            <summary>
                {text}
            </summary>
            <ul className="treeview-menu treeview-submenu">
            {
                submenuitems?.map((element) => {
                    return <SubMenuItem url={element.url ?? ""} text={element.text ?? ""} submenuitems={element?.subMenu ?? undefined} permissionId={permissionId} />
                })
                }
            </ul>
        </details>
    </li>)
}