import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface Props{
    size:number,
    topMargin?: string
}

export function LoaderSpinner({size, topMargin}: Props){
    return ( <div style={{display:'flex',justifyContent:'center', alignContent:'center', marginTop:topMargin}}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
        </div>)
}