import { redirect, useLoaderData, useParams, useSubmit, useNavigate } from "react-router-dom";
import { Select, Input, Card, Button, Layout, Typography, Upload, Cascader, Divider, Space, DatePicker, notification, Alert, Spin } from 'antd';
import { Form, Modal } from 'antd';
import { useEffect, useRef, useState } from "react";
import type { DatePickerProps, SelectProps } from 'antd';
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { createFormData } from "../../helpers/formHelpers";
import { clientReview, createGrievanceCall, getComplaintStatus } from '../../service/httpsCalls'
import { LoaderSpinner } from "../../components/common/Loader";
import https from "../../service/httpsService";
import { useReactToPrint } from "react-to-print";
import { PrinterFilled } from "@ant-design/icons";

const { TextArea } = Input;
const { Header, Content } = Layout;
const { Title, Text } = Typography;

export async function loader({ params }: any) {
    if (params.id != null) {
        let loading_ = false
        const grievance = await getGrievance(params.id);
        return { grievance, loading_ };
    } else {
        return { id: 0, description: "" };

    }
}

export async function newGrievance() {
    return {};
}
export async function action({ request, params }: any) {
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    if (params.id != null) {
        await updateGrievance(params.id, updates);
        return redirect(`/grievances/${params.id}`);
    }

    const result = await createGrievance(updates);
    return redirect(`/grievances/${result.id}`);
}

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
    disableCheckbox?: boolean;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';
type Member = { id: number, memberId?: string, memberName: string, dateOfBirth?: string }

async function getGrievance(id: number) {
    const res = await fetch(`api/v1/grievances/${id}`, {
        credentials: "include",
    })
        .then(response => response.json())
    return res;
}

async function createGrievance(model: any) {
    const res = await fetch(`api/v1/grievances/create`, {
        method: 'POST',
        body: JSON.stringify(model)
    })
        .then(response => response.json())
    return res;
}

async function updateGrievance(id: any, model: any) {
    const res = await fetch(`api/v1/grievances`, {
        method: 'PUT',
        body: JSON.stringify(model)
    })
        .then(response => response.json())
    return res;
}


const Grievance: React.FC<{ "type"?: string }> = ({ type = "detail" }) => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const { grievance, loading_ }: any = useLoaderData() || {};
    const [dateValue, setDateValue] = useState('');
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [cascOptions, setCascOptions] = useState<Option[]>([]);
    const [api, contextHolder] = notification.useNotification();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState(false);
    const [itemLoading, setItemLoading] = useState(true)
    const sub = useSubmit();
    const [modal, setModal] = useState<boolean>(false)
    const [note, setNote] = useState('')
    const [ApprovalLoading, setApprovalLoading] = useState(false)
    const [approvalError, setApprovalError] = useState(false)
    const [denyLoading, setDenyLoading] = useState(false)
    const [cascLoader, setCascLoader] = useState(false);
    const [stautsOption, setStatusOption] = useState<Option[]>([]);
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const ref = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        pageStyle: '@media print { .ant-upload, button { display: none !important; }  .anticon,.ant-form-item-extra { display: none !important; } input, input:disabled,.ant-input,.ant-select-selection-item{color:black !important;} #userName{display: flex !important;} }'
    });

    useEffect(() => {
        setDisabled(loading)
    }, [loading])

    const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
        api[type]({
            message: message,
            description: description
        });
    };

    const getPanelValue = (searchText: string) => {
        if (!searchText)
            return [];
        https.get(`api/v1/members?searchText=${searchText}`).then((response) => {
            return response.data;
        }).then((data) => {
            const temp = data?.map((v: Member) => {
                return renderItem(v);
            })
            setOptions(temp);
        })
            .catch(error => console.error("Fetching error:", error));
    };

    const getCascadeOptions = async () => {
        setCascLoader(true);
        let temp: Option[] = [];
        const memberId = form.getFieldValue("memberId")?.value;
        if (memberId) {
            try {
                const response = await https.get(`api/v1/trips?memberId=${memberId}`);
                // Directly use `response.data` to access the returned data.
                temp = response.data.map((v: any) => {
                    return renderOption(v);
                });
                setCascOptions(temp);
            } catch (error) {
                console.error("Failed to fetch trips:", error);
            } finally {
                // Ensure `setCascLoader(false)` is called in a finally block to always turn off the loader, regardless of request success or failure.
                setCascLoader(false);
            }
        }
    };


    const renderChild = (tripLegs: any, tripNo: any): Option[] => {
        return tripLegs.map((tl: any) => {
            return {
                value: tl.tripLegId,
                label: tripNo + "-" + String.fromCharCode(tl.triplegSeqNum + 64),
            };
        });
    };

    const renderOption = (trip: any): Option => ({
        value: trip.tripId,
        label: trip.tripNo + " " + new Date(trip.dateOfService).toLocaleDateString(),
        children: renderChild(trip.tripLegs, trip.tripNo)
    });

    const renderItem = (member: Member) => ({
        value: member.id,
        label: member.memberName,
        ...member
    });

    const onSelect = (data: string) => {
        form.setFieldValue("references", null);
        getCascadeOptions();
    };

    const onChange = (value: string[], selectedOptions: any) => {
        form.setFieldsValue({ references: value });
        // Optionally trigger validation for this field
        form.validateFields(['references']);
    };

    const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (dateString) {
            form.setFieldsValue({ dueDate: dateString });
            // Optionally trigger validation for the dueDate field
            form.validateFields(['dueDate']);
        }
    };

    const onSave = () => {
        form.validateFields()
            .then((values) => {
                submitForm(values);
            })
            .catch((errorInfo) => {
                console.log("errorInfor", errorInfo)
            });
    };
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    // const notificated = useRef(false)
    const props: UploadProps = {
        disabled: disabled,
        customRequest: onSave,
        onChange: (info) => { info.file.status = 'done'; },
        onRemove: (file) => {
            if(loading) return;
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            if(type == "create") return;
            const index2 = grievance?.fileDetails?.findIndex((x: any) => x.fileName == file.name);
            grievance?.fileDetails?.splice(index2, 1);
            onSave();
        },
        beforeUpload: (file) => {
            const maxLimit = 10
            const existingFilesLength = grievance?.fileDetails?.length ?? 0
            if (existingFilesLength >= maxLimit) {
                openNotificationWithIcon('error', 'Error', `Only 10 files allowed`);

                return Upload.LIST_IGNORE;
            }

            if (type == "create") {
                const index = fileList.indexOf(file);
                const maxInitalLimit = 1
                if (fileList?.length >= maxInitalLimit) {
                    openNotificationWithIcon('error', 'Error', `Only 1 file allowed. You can upload more after submitting.`);
                    return Upload.LIST_IGNORE;
                }
            }

            const maxFileSize = 10 * 1024 * 1024; 
            if (file.size > maxFileSize) {
                openNotificationWithIcon('error', 'Error', `${file.name} file upload failed (exceeds 10MB)`);
                console.error("File size exceeds the limit of 10MB");
                return Upload.LIST_IGNORE;
            }

            // Check for duplicate file names in the fileList and existing files (if editing)
            const isDuplicate = fileList.some(f => f.name === file.name) || (grievance?.fileDetails?.some((f: any) => f.fileName === file.name));
            if (isDuplicate) {
                // Display error notification and prevent the file from being added
                openNotificationWithIcon('error', 'Error', 'A file with the same name already exists. Please use a different name.');
                return Upload.LIST_IGNORE; // Prevent the file from being added to the list
            }

            setFileList([...fileList, file]); // Add file to the list if no duplicates are found
            if (type == "create") {

                return false; // Return false to prevent auto upload
            } else {
                return true;
            }
        },

        fileList,
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e.fileList;
    };

    const complaintStatusDropdownValue = async () => {
        var res = await getComplaintStatus()
        const temp = res?.map((v: any) => {
            return {
                value: v?.id,
                label: v?.name,
                ...v
            }
        })
        setStatusOption(temp)
    }

    useEffect(() => {
        form.resetFields();
        getCascadeOptions();
        complaintStatusDropdownValue()
        if (grievance != null)
            form.setFieldValue("files", processExistingFiles(grievance.fileDetails));
        setDisabled(grievance?.status === "Resolved");
    }, [type, grievance]);

    useEffect(() => {
        setItemLoading(false)
    }, [loading_])

    const processExistingFiles = (files: any) => {
        const arr = [];
        for (let i = 0; i < files?.length; i++) {
            arr.push({
                "uid": files[i].fileId,
                "name": files[i].fileName,
                "status": "done",
                "url": `api/v1/Document?folder=Complaints&blobName=${files[i].blobName}&fileName=${files[i].fileName}`
            })
        }
        return arr;
    }

    function flattenToArray(obj: any) {
        const arr = [];
        for (let i = 0; i < obj.length; i++) {
            const temp = [];
            if (obj[i].tripId != null)
                temp.push(obj[i].tripId);
            if (obj[i].triplegId != null)
                temp.push(obj[i].triplegId);
            arr.push(temp);
        }
        return arr;
    }

    const submitForm = (values: any) => {
        setLoading(true);
        values.id = parseInt(id ?? "0");
        values.memberId = values.memberId.value;
        const obj = [];
        for (let i = 0; i < values.references?.length; i++) {
            const compare: any = {
                ComplaintInfoId: values.id,
                memberId: values.memberId,
                tripId: values.references[i][0],
                triplegId: values.references[i][1]
            };
            if (grievance && grievance.references) {
                const result = grievance.references.find((v: any) => v.tripId == compare.tripId && v.triplegId == compare.triplegId);
                if (result != undefined) {
                    compare.id = result.id;
                }
            }
            obj.push(compare);
        }
        values.references = obj;
        if (values.references.length == 0 && values.memberId != null) {
            values.references = [{ ComplaintInfoId: values.id, memberId: values.memberId }];
        }
        //values.files = fileList.map((f) => f as RcFile);
        const temp = { ...grievance, ...values };
        const formData = createFormData(temp);

        if(values?.files?.length){
            formData.append('files', values?.files[values?.files?.length - 1]?.originFileObj as RcFile);
        }

        const method = type == "create" ? 'POST' : 'PUT';

        createGrievanceCall(formData, method, (res: any) => {
            if (res !== 'error') {
                const message = type == "create" ? "Grievance created successfully" : "Grievance updated successfully";
                setLoading(false);
                setFileList([]);
                openNotificationWithIcon("success", "Success", message);
                navigate(`/grievances/${res.data.id}${window?.location?.search}`);
                return res.data
            } else {
                const errorMessage = "An error occurred while processing your request. Please try again later.";
                setLoading(false);
                openNotificationWithIcon('error', 'error', errorMessage)
            }

        }, id)
    };

    function handleDeny(e: any) {
        e.preventDefault()
        setModal(true)
    }

    function handleApprovalProcess(e: any, state: string): void {

        e.preventDefault()
        if (state === "deny") setDenyLoading(true);
        else setApprovalLoading(true)

        clientReview({
            "complaintId": parseInt(id || '0'),
            "resolved": state === "approval" ? true : false,
            "denialNote": state !== "approval" ? note : ""
        }, (res) => {
            if (res == "success") {
                setModal(false)
                setApprovalError(false)
                openNotificationWithIcon("success", "Success", "Grievance updated successfully");
                navigate(`/grievances/${grievance.id}${window?.location?.search}`);
            }
            else if (res == "error") setApprovalError(true)

            setApprovalLoading(false)
            setDenyLoading(false)
        })
    }

    function afterCloseModal() {
        setApprovalError(false)
        setNote('')
    }

    function onCancel() {
        navigate(`/grievances`);
    }

    return (
        <>
            {contextHolder}
            <Layout ref={ref}>
                <Modal title="Add Note" open={modal}
                    onCancel={e => setModal(false)}
                    onOk={e => handleApprovalProcess(e, 'deny')}
                    okText="Submit"
                    cancelText="Cancel"
                    afterClose={afterCloseModal}
                    confirmLoading={denyLoading}
                >
                    <div style={{ margin: '15px' }}>
                        <TextArea
                            rows={10}
                            value={note}
                            onChange={e => setNote(e.target.value)}
                            placeholder="Add note"
                        />
                        {approvalError && <div style={{ marginTop: '5px' }}>
                            <Alert message="Error! try again" type="error" />
                        </div>}
                    </div>
                </Modal>
                <div id='userName' style={{ display: "none", padding: "1rem" }}>For user: {JSON.parse(window.localStorage.getItem("userData")??"")?.userName}</div>
                <Form id="grievanceForm" layout="vertical" form={form}>

                    <Header style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: '#eff1f5' }}>
                        {itemLoading ? <LoaderSpinner size={24} /> : <Title level={4} style={{ margin: 0 }}>{type != "create" ? grievance.id : "New Grievance"}</Title>}
                        <div style={{ display: 'flex' }}>
                            <Button style={{ marginRight: '5px' }} htmlType="submit" onClick={onCancel} >Cancel</Button>
                            {
                                grievance?.status === "Client Review" &&
                                <div style={{ display: "flex", marginRight: '30px' }}>
                                    <Button danger htmlType="submit" onClick={handleDeny}>Deny</Button>

                                    <Button style={{ backgroundColor: '#37ad43', borderColor: '#37ad43', marginLeft: '5px' }} type="primary" htmlType="submit" loading={ApprovalLoading} onClick={e => handleApprovalProcess(e, 'approval')}>Approve</Button>
                                </div>}
                            {type != "create" && <Button onClick={handlePrint} icon={<PrinterFilled />} />}

                            {grievance?.status != "Resolved" && <Button style={{ backgroundColor: '#007bff', borderColor: '#007bff', marginLeft: '5px' }} type="primary" htmlType="submit" loading={loading} onClick={onSave} >Save</Button> }
                        </div>
                    </Header>

                    <Content style={{ padding: "1rem" }}>
                        <Card title="Basic Info">



                            <div className="card-body">

                                <Form.Item
                                    label='Case Id'
                                    name='clientCaseId'
                                    initialValue={type !== "create" ? grievance?.clientCaseId : null}
                                    rules={[{ required: true, message: 'Please enter your internal case Id' }]}
                                >
                                    <Input disabled={grievance?.status === "Resolved"} />
                                </Form.Item>

                                <Form.Item
                                    label='Member'
                                    rules={[{ required: true, message: 'Please select your member' }]}
                                    name="memberId"
                                    extra='Name or Id number'
                                    initialValue={type !== "create" ? { label: grievance?.references[0]?.memberName, value: grievance?.references[0]?.memberId } : undefined}
                                >
                                    <Select
                                        showSearch
                                        options={options}
                                        style={{ width: '100%' }}
                                        onSelect={onSelect}
                                        onSearch={getPanelValue}
                                        filterOption={false}
                                        labelInValue
                                        disabled={grievance?.status === "Resolved"}
                                    />
                                </Form.Item>


                                <Form.Item label='Trip' validateTrigger={['onChange', 'click']} name="references" initialValue={type !== "create" ? flattenToArray(grievance.references) : undefined}>
                                    <Cascader
                                        style={{ width: '100%' }}
                                        options={cascOptions}
                                        onChange={() => onChange}
                                        multiple
                                        maxTagCount="responsive"
                                        notFoundContent={cascLoader ? <Spin size="small" /> : 'No trips found.'}
                                        disabled={grievance?.status === "Resolved"}
                                    />
                                </Form.Item>

                                <Form.Item name="typeId" rules={[{ required: true, message: 'Please select your type' }]} label='Type' initialValue={type !== "create" ? grievance.typeId : undefined}>
                                    <Select
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: "Standard", value: 2 },
                                            { label: "Urgent", value: 5 },
                                            { label: "DMHC", value: 3 },
                                            { label: "Accident/Injury/Incident", value: 6 },
                                            { label: "Crisis Alert", value: 7 }
                                        ]}
                                        disabled={grievance?.status === "Resolved"}
                                    />
                                </Form.Item>
                                <Form.Item name="statusId" rules={[{ required: true, message: 'Please select your status' }]} label='Status' initialValue={type !== "create" ? grievance?.statusId : 1}>
                                    <Select
                                        style={{ width: '100%' }}
                                        options={stautsOption}
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item name="dueDate" label="Due Date" initialValue={grievance?.dueDate} rules={[{ required: true, message: 'Please select your due date' }]} >
                                    {type === "create" ? (
                                        <Select
                                            style={{ width: '100%' }}
                                            options={[
                                                { label: "5 business days", value: "5 business days" },
                                                { label: "10 calendar days", value: "10 calendar days" },
                                                { label: "24 business hours", value: "24 business hours" }
                                            ]}
                                            placeholder="Select a due date"
                                            onChange={value => form.setFieldsValue({ dueDate: value })}
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space style={{ padding: '0 8px 4px' }}>
                                                        <Text>Custom Date:</Text>
                                                        <DatePicker
                                                            onChange={(date, dateString) => form.setFieldsValue({ dueDate: dateString })}
                                                            format="MM/DD/YYYY"
                                                            disabledDate={disabledDate}
                                                        />
                                                    </Space>
                                                </>
                                            )}>
                                        </Select>
                                    ) : (
                                        <Input disabled value={grievance?.dueDate || ''} />
                                    )}
                                </Form.Item>

                                <Form.Item name="description" rules={[{ required: true, message: 'Please enter description' }]} label='Allegations' initialValue={type != "create" ? grievance.description : ""}>
                                    <TextArea rows={3} name="description" disabled={grievance?.status === "Resolved"} />
                                </Form.Item>
                                <Form.Item label="Attachments (optional)">
                                    <Form.Item name="files" valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                                        <Upload.Dragger {...props} >
                                            <p className="ant-upload-drag-icon">
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                        </Card>
                    </Content>

                </Form>
            </Layout>
        </>
    );
}

export default Grievance;