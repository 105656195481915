import Menu1 from "./menu";
import '../../layout.css';
import logoText from '../../assets/logotextwhite.svg';
import logo from "../../assets/logo-dark.svg";
import { useState, useMemo, ReactNode } from "react";
import { Link } from "react-router-dom"
import { Dropdown, Menu } from 'antd';

// Define an interface for the data prop
interface DataProp {
    permissions?: any; // Specify a more specific type if possible
    userType?: string;
    userName?: string;
}

// Define an interface for the Layout component props
interface LayoutProps {
    children: ReactNode;
    data: DataProp;
}

interface SideBarProps {
    data: DataProp;
}

const Layout: React.FC<LayoutProps> = ({ children, data }) => {
    const [isCollapsed, setCollapse] = useState(false);
    const SideBar: React.FC<SideBarProps> = ({ data }) => useMemo(() => <Menu1 permissions={data?.permissions} userType={data?.userType} parentCollapsed={isCollapsed}  />, [data])
    if (document.referrer && !window.localStorage.getItem("Referrer")) {
        window.localStorage.setItem("Referrer", document.referrer);
    }
    const baseUrl = window.localStorage.getItem("Referrer");
    const collapse = () => {
        setCollapse(!isCollapsed);
    }
    const menu = (
        <Menu className="dropdown-menu-list">
            {/*<Menu.Item key="0">*/}
            {/*    <div onClick={e => e.stopPropagation()}>*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            id="spaMode"*/}
            {/*            checked*/}
            {/*        /> New layout*/}
            {/*    </div>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="1">
                <a className="menu-list-logf" href={baseUrl + "/Account/LogOff"}>Log Off</a>
            </Menu.Item>

        </Menu>
    );
    let errorIcon: boolean = false;
    return (
        <div className={isCollapsed ? "sidebar sidebar-collapse" : "sidebar"}>
            <div>
                <aside id="sidebar" className="main-sidebar">
                    <div className="headerLogo">
                        {/* <a href="/Account/Dashboard"> */}
                        <Link to={baseUrl + "/Account/Dashboard"}>
                            <div className="new-icon-Container" style={{ height: 'auto' }}>
                                <img src={logo} className="headerLogoImg" />
                            </div>
                            <img className="headerLogoText" src={logoText} />
                        </Link>
                        {/* </a> */}
                    </div>
                    <hr style={{ width: '100%', opacity: '0.25', margin: '16px 0 !important' }} />
                    <SideBar data={data} />
                    <a onClick={collapse} className="sidebar-toggle" data-toggle="offcanvas" role="button" style={{ textDecoration: 'none', marginTop: 'auto' }}>
                        <div className="new-icon-Container">
                            <span className="material-icons-outlined">
                                menu
                            </span>
                        </div>
                    </a>
                </aside>
                <div className="right-panel" id="right-panel">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        {/*<div class="col-lg-6">
                      <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search for..." id="search">
                          <span class="input-group-btn">
                              <button class="btn btn-default" type="button">
                                  <span class="material-icons-outlined" style="font-size: 14px;">
                                      search
                                  </span>
                              </button>
                          </span>
                      </div>*/}
                        {/* /input-group */}
                        {/*</div>
                  <div class="input-group">

                      <input id="search" class="form-control" type="text" placeholder="Search" data-bs-toggle="dropdown">
                      <span class="input-group-btn">
                          <button class="btn btn-default" type="button">
                          </button>
                      </span>

                      <ul id="searchResults" class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="width: 94%"></ul>
                  </div>*/}
                        <ul className="navbar-nav ml-auto p-1">
                            <li className="nav-item">
                                {/*<span className="navbar-text p-2">ztestclient@ztest.com</span>*/}
                                <span className="navbar-text p-2">{data?.userName}</span>
                            </li>
                            {/* <li className="nav-item dropdown" style={{ marginRight: '1rem' }}>
                                <a className="account-icon nav-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                                    <img src="" className="rounded-circle" style={{ verticalAlign: 'baseline' }} height={24} alt="Avatar" loading="lazy" onError={() => { console.log('error'); errorIcon = true; this.style.display = 'none'; }} />
                                    <span className='material-icons' >account_circle</span>
                                    <span className='material-icons'>arrow_drop_down</span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li>
                                        <a className="dropdown-item" href={baseUrl + "/Account/LogOff"}>Log Off</a>
                                    </li>
                                </ul>
                            </li> */}
                            <li className="nav-item navbar-text">
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} role="button">
                                        <span className='material-icons header-icon' >account_circle</span>
                                        <span className='material-icons header-icon'>arrow_drop_down</span>
                                    </a>
                                </Dropdown>
                            </li>
                        </ul>
                    </nav>
                    <div className="content-area">
                        <div aria-live="polite" aria-atomic="true" className="position-relative" style={{ zIndex: 1040 }}>
                            <div id="toast-container" className="bootstrapiso position-absolute top-0 end-0 p-3">
                            </div>
                        </div>
                        <div id="body">
                            {children}
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};
export default Layout