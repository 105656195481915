

export function checkToken(){
    let cookies =  document.cookie.split(';')
    let flag =  cookies.some(cookie=>{
        let item = cookie.trim();
        return item.startsWith(process.env.token+ '=')
    })
    // return true
    return flag
}
