import https, { https_ } from './httpsService'
import * as api from './api'
import { checkToken } from '../helpers/commonHelpers';
import { ClientReview } from '../types';
let v1 = '/api/v1'

export async function getGrievances() {

    let res = await https.get("/api/v1/grievances")
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function createGrievanceCall(formData: any, type: string, cb: any, id?: any) {

    let request: any = type == 'POST' ? https_.post : https_.put
    try {
        const url = type == "POST" ? `api/v1/grievances` : `api/v1/grievances`;
        let res = await request(`${url}`, formData, { timeout: 50000 })
        cb(res)
    }
    catch (err) {
        console.log(err)
        cb('error')
    }

}

export async function search(data: any, cb: any) {

    let res = await https.post(v1 + api.GRIEVANCES_SEARCH, data, { timeout: 50000 })
        // let res = await https.post('https://ccerrtsnet6api-dev.azurewebsites.net/api/v1/grievances/search',data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            console.log(err)
            cb('error')
        });
}

export async function complaintSearch(data: any, cb: any) {

    let res = await https.post(v1 + api.COMPLAINT_SEARCH, data, { timeout: 50000 })
        // let res = await https.post('https://ccerrtsnet6api-dev.azurewebsites.net/api/v1/grievances/search',data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            console.log(err)
            cb('error')
        });
}

export function exportAllComplaint(data: any, cb: any) {

    let res = https.post(v1 + api.EMPLOYEES_GetExportList, data, { timeout: 5000000 });
}

export async function getEmployees(searchText: string) {
    let res = await https.post(v1 + api.EMPLOYEES_SEARCH, { searchText: searchText, pageIndex: 0, pageSize: 100, designationIds: [2001, 2002], considerAll: false })
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}
export async function getAganistType() {
    let res = await https.get('api/v1/Grievances/GetAgainstType')
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function getSourceType() {
    let res = await https.get('api/v1/Grievances/GetSourceType')
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function getComplaintStatus() {
    let res = await https.get('api/v1/Complaints/GetComplaintStatuses')
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}
export async function clientReview(data: ClientReview, cb: (arg: string) => void) {
    https.put(v1 + api.CLIENT_REVIEW, data)
        .then((res) => cb('success'))
        .catch((err) => cb('error'))
        .finally(() => cb('complete'))

}

export async function getClients() {

    let res = await https.get(v1 + api.CLIENTS)
        .then((response) => {
            return response.data
        })
        .catch((err) => {
            console.log(err)
        });
    return res;
}

export async function multi(data: any, cb: any) {
    let res = await https.post(v1 + api.COMPLAINT_MULTI, data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            console.log(err)
            cb('error')
        });
    return res;
}

export async function getAllGrievance(data: any, cb: any) {
    let res = await https.post('api/v1/grievances/GetAllGrievance', data)
        .then((response) => {
            return cb(response.data)
        })
        .catch((err) => {
            console.log(err);
            cb('error')
        });
    return res;
}